import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Hi. Salut. Hej. Hola. Hai.`}</h1>
    <p>{`I'm Tom. I'm passionate about clean system design, Cloud, Python and Infosec.`}</p>
    <p>{`I blog about novel use cases of open source technology in enterprise, Python and ramble about cloud.`}</p>
    <p>{`Certified AWS Solution Architect.`}</p>
    <hr></hr>
    <p><em parentName="p">{`All views expressed here are my own`}</em></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      